// module name
$name: 'filter';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: $spacing-big;

  &__select {
    width: 100%;
    height: 4rem;
    margin-bottom: $spacing-small;
    padding: $spacing;
  }
}

@media (min-width: $media-desktop) {
  .#{$base} {
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &__select {
      height: 4rem;
    }

    &__container {
      margin-right: $spacing;
    }

  }
}
