.wp-block-column {



  p { margin-bottom: $spacing-big;}


}


.wpcf7 {
  padding: 0 $spacing !important;

  p { padding: 0;}
}

.wpcf7 .g-recaptcha {
  margin-bottom: $spacing;
}

.wpcf7-form-control {

}

.wpcf7 label{ display: block; }

.wpcf7-text {
  height: 50px;
  border: 0;
  font-size: 1.5rem;
  padding: $spacing 0;
  border-bottom: 2px solid #CCC;
  margin-bottom: $spacing-big;
  width: 100%;
}

.wpcf7-textarea {
  border: 0;
  width: 100%;
  display: block;
  font-size: 1.5rem;
  padding: $spacing 0;
  border-bottom: 2px solid #CCC;
  margin-bottom: $spacing-big;
  font-family: $font-base-family;
}

.wpcf7-submit {
  @include button($color-brand);
  max-width: 50%;
  float: right;
}

@media (min-width: $media-desktop) {

  .wpcf7 {

  }

  .wpcf7-field-50, .wpcf7-field-100 {
    width: 50%;
    display: inline-block;
    float: left;
    padding: $spacing;
    margin-left: -$spacing;
  }
  .wpcf7-field-100 {
    width: 100%;
  }

}
