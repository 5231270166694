// module name
$name: 'hero';
$base: $style-prefix + $component + $name;

.#{$base} {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: $menu-bar-height + 2rem;
  margin-bottom: $spacing;
  min-height: 70vh;
  flex: 1;

  // IE 11 styling
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    max-height: 450px;
  }

  ::first-letter {
    text-transform: capitalize;
  }

  &--big {
    height: 100vw;
  }

  &--single {
    height: 90vh;
  }

  &--homepage {

    &::after {
      position: relative;
      margin: $spacing;
    }

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      z-index: 91;
      position: absolute;
      top: 0;
      left: 0;
      background-color: $color-primary;
      opacity: 0.8;
    }

  }

  &--homepage & {

    &__title {
      margin: $spacing 0;
      text-align: center;
      color: #FFF;
      font-weight: $font-bold;
      z-index: 95;
      position: relative;
      font-family: $font-special-family;
      line-height: 110%;
      margin-bottom: $spacing;
    }

    &__subtitle {
      position: relative;
      z-index: 91;
      color: $color-brand-lighter;
      font-size: 1.3rem;
      display: block;
      text-align: center;
    }

    &__image {
      z-index: 90;
    }

    }

  &__container {
    @include grid-container;
    padding: 0 $spacing $spacing-section;

    &--center {
      text-align: center;
      width: 100%;
      padding-right: $spacing;
    }

  }


  &__image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }

  &__text {
    color: #FFF;
    font-size: $font-paragraph-size;
    margin-bottom: $spacing;
    line-height: $font-base-lineheight;
    z-index: 2;
    text-shadow: $font-shadow;
  }



  &__button {
    display: inline-flex;
    text-decoration: none;
    text-align: center;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: $button-font-size;
    border-radius: $border-radius;
    font-family: $font-special-family;
    font-weight: $font-bold;
    padding: $button-padding-small;
    align-self: baseline;
    align-items: center;
    min-height: 3.2rem;

    & svg {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: $spacing;
    }

  }

}

@media screen and (min-width: $media-tablet) {

  .#{$base} {
    position: relative;
    margin-bottom: $spacing-section;
    height: 50vw;
    overflow: hidden;
    box-sizing: content-box;
    padding: 10rem 0;
    max-width: $grid-container;
    margin: auto;
    max-height: 80vh;
    min-height: 50vh;

    &--homepage {
      display: flex;
    }

    &--homepage & {

      &__title {
        text-align: left;
        font-size: 4rem;
        font-weight: $font-bold;
        padding-right: $spacing-big;
      }

      &__subtitle {
        text-align: left;
        font-size: 1.8rem;
        margin-bottom: 0;
      }

    }

    &__navbar {
      display: none;
    }

    &__image {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1;
    }

    &__heading { font-size: $heading-title-font-size * $desktop-factor; }

    &__text {
      color: #FFF;
      font-size: $font-paragraph-size * $desktop-factor;
      margin-bottom: $spacing * $desktop-factor;
      line-height: $font-base-lineheight * $desktop-factor;
      z-index: 2;
    }

    &__container {
      flex: 1 0 0;
      align-items: center;
      margin: 12rem 0;

      &--center {
        padding-right: $spacing;
        padding-bottom: $spacing-big;
      }

    }

    &__button {
      display: inline-flex;
    }

  }

}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    position: static;

    &--homepage & {
      &__title {
        font-size: 7rem;
      }
    }

  }
}
