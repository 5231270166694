// module name
$name: 'content';
$base: $style-prefix + $layout + $name;

.#{$base} {
  @include grid-container($grid-content);
  margin-bottom: $spacing-section;

  &--wide {
    @include grid-container;
  }

  a {
    color: $color-brand;
    text-decoration: none;
  }

  p, &__paragraph {
    line-height: $font-base-lineheight;
    margin-bottom: $font-paragraph-spacing;
    padding: 0 $spacing;
    font-size: $font-paragraph-size;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      display: block;
    }
  }

  .mobile-down .wp-block-column:first-of-type {
    order: 2;
  }

  .sharedaddy {
    li::before {
      display: none;
    }
  }

  ul, ol {
    position: relative;
    display: block;
    padding: 0 $spacing $spacing-big;
    margin: 0;
  }

  ol {
    padding: 0 3rem;
  }

  ul li {
    padding-left: 2rem;
    position: relative;
    line-height: 140%;
    font-size: $font-paragraph-size;

    &::before {
      content: '';
      position: absolute;
      display: block;
      height: .6rem;
      width: .6rem;
      background-color: $color-brand;
      border-radius: 50%;
      left: 0;
      top: 8px;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      width: auto;
      display: inline-block;
      height: 500px;
      line-height: 0;
      font-size: 0;
    }
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: $font-special-family;
    font-weight: $font-strong;
    padding: 0 $spacing 0;
    color: $color-brand;
    margin-top: 0;
    margin-bottom: $spacing;
  }

  h2 {
    font-size: 1.8rem;
  }

}

@media screen and (min-width: $media-tablet) {
  .#{$base} {
    .mobile-down .wp-block-column:first-of-type {
      order: 0;
    }
  }
}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    display: flex;
    flex-direction: column;

    h2 {
      font-size: 3rem;
      margin-bottom: $spacing-big;
    }

    p {
      font-size: $font-base-fontsize * $desktop-factor;
      line-height: $font-base-lineheight * $desktop-factor;
    }

    ul li {
      font-size: $font-paragraph-size * $desktop-factor;
    }
  }
}

@media print {
  .#{$base} {
    display: flex;
    flex-direction: column;

    p {
      font-size: $font-base-fontsize * $desktop-factor;
      line-height: $font-base-lineheight * $desktop-factor;
    }

    img {
      display: block;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto;
    }

    ul li {
      font-size: $font-paragraph-size * $desktop-factor;
    }
  }
}
