@mixin button($style: $button-color-primary, $width: 100%, $padding: $button-padding) {
  background-color: $style;
  padding: $padding;
  border-radius: 3.5rem;
  text-decoration: none;
  font-family: $font-special-family;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  border: 2px solid $style;
  -webkit-appearance: none;

  &:hover {
    opacity: 0.9;
  }

  @if $style == $button-color-primary {
    color: #FFF;
  }

  @if $style == $button-color-disabled {
    color: darken($style, 20);
  }

  width: $width;

  @media screen and (min-width: $media-desktop) {
    font-size: 1.4rem;
    font-weight: $font-bold;
  }

}

@mixin textlink() {
  color: $color-brand-lighter;
  padding: $spacing 0;
  font-weight: $font-bold;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 90%;
  &:hover {
    opacity: 0.9;
  }
}
