// module name
$name: 'list';
$base: $style-prefix + $module + $name;

.#{$base} {
  margin-left: -2rem;

  &__title {
    color: $color-brand;
    font-weight: $font-bold;
    margin-bottom: $spacing-big * 1.5;
  }

  &__item {
    color: $color-brand;
    position: relative;
    padding-left: 5rem;
     margin-bottom: $spacing;

    &::after {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      color: $color-brand-lighter;
      content: '\f00c';
      position: absolute;
      display: block;
      left: 2rem;
      top: 0rem;
      font-size: 1.8rem;
    }

  }
}

@media (min-width: $media-desktop){
  .#{$base} {

    &__item {
      font-size: 1.8rem;
    }

    &__title {
      font-size: 3rem;
    }
  }
}
