// component name
$name: 'overview';
$base: $style-prefix + $component + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  padding: 0 $spacing;

  &__subtitle {
    display: none;
  }

  &--company .cd-mod-box-general__container {
    justify-content: center;
  }

  &__title {
    flex: 1 1 0;
    font-size: $module-header-small;
    font-weight: $font-strong;
    margin-bottom: $spacing-big;
    margin-top: 0;
    text-align: center;
    width: 100%;
    color: $color-brand;

    &--left {
      text-align: left;
    }

    &--right {
      text-align: right;
    }

  }

  &__button {
    margin: $spacing-section 0;
    @include button($width: auto);
    flex-basis: 100%;
    margin: $spacing;
  }

}

@media (min-width: $media-desktop) {

  .#{$base} {

    &__title {
      font-size: $module-header-big;
      margin-bottom: $spacing-big * 3;
    }

    &__subtitle {
      color: $color-brand;
      font-size: $font-subheading-size * 1.5;
      text-align: center;
      display: block;
      line-height: 1.4rem * 2;

      &--right {
        text-align: right;
      }

      &--left {
        text-align: left;
      }

    }

    &__button {
      width: auto;
      flex-basis: auto;
      margin: $spacing-big auto 0;
    }
  }

}
