// component name
$name: 'faq';
$base: $style-prefix + $module + $name;

.#{$base} {
  padding: $spacing;
  display: flex;
  flex-direction: column;

  &__title {
    text-align: center;
    font-size: $module-header-small;
    font-weight: $font-strong;
    margin-bottom: $spacing-big * 2;
    color: $color-brand;
  }

  &__list {
    border-top: 1px solid $font-color-background;
    padding-top: $spacing-big;
  }

  &__item {
    padding-bottom: $spacing-big;
    border-bottom: 1px solid $font-color-background;
    margin-bottom: $spacing-big;
  }

  &__link {
    color: $font-color-background;
    text-decoration: none;
  }

  &__button {
    @include button($width: auto);
    margin: $spacing auto $spacing-big;
  }

}

@media screen and (min-width: $media-desktop) {
  .#{$base} {

    &__title {

    }

  }

}
