// module name
$name: 'company';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  flex: 0.5 0.5 0;

  &__logo {
    max-width: 100%;
    width: auto;
    margin: $spacing auto 0;
    max-height: 10rem;
    flex-basis: auto;
    flex: 1 1 0;
  }

  &__name {
    text-align: center;
    flex-basis: 100%;
    font-weight: $font-bold;
    margin-top: $spacing;
  }

  &__button {
    margin: $spacing-section 0;
    @include button($width: auto);
    flex-basis: auto;
    margin: $spacing auto auto;
  }

  &__textlink {
    @include textlink;
    width: 100%;
    text-align: center;
  }

  &__box {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 0;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #FFF;
    padding: $spacing $spacing $spacing-big;
    box-shadow: 0 0 17px rgba(0,0,0,.15);
    margin: 0 $spacing $spacing-big;
  }

}
@media screen and (min-width: $media-tablet) {

  .#{$base} {
    &__box {
      min-width: 20%;
      max-width: 20%;
    }
  }

}
