// component name
$name: 'rating';
$base: $style-prefix + $module + $name;

.#{$base} {
  display: flex;
  flex-wrap: nowrap;
  font-size: 2.5rem;
  line-height: 2.7rem;

  &__star {
    color: #ECECEC;

    &--filled {
      color: #FFDE35;
    }

    &--half {
      position: absolute;
      top: 0;
      z-index: 1;
      overflow: hidden;
      width: 50%;
    }

    &Container {
      position: relative;
    }

  }


}
