/**
* This will be added to all classes,
* to rule out all third party css code collision.
**/
.rack-lay-background {
  padding: 2rem 0;
  background-color: #eff2f5;
  margin-bottom: 2rem; }
  .rack-lay-background--mobile {
    background-color: transparent; }
  .rack-lay-background--brand {
    background-color: #12124e; }
  .rack-lay-background--dark {
    background-color: #0e0e3a; }
  .rack-lay-background--light {
    background-color: rgba(255, 255, 255, 0.4); }
  .rack-lay-background--nomargin {
    margin-bottom: 0; }

@media (min-width: 1000px) {
  .rack-lay-background {
    padding: 6rem 0;
    margin-bottom: 6rem; }
    .rack-lay-background--mobile {
      background-color: rgba(204, 204, 204, 0.25); }
    .rack-lay-background--nomargin {
      margin-bottom: 0; } }

.rack-mod-cards {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  display: grid;
  grid-gap: 1rem;
  grid-template-rows: auto;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
  padding: 1rem 1rem 2rem;
  margin-bottom: 2rem;
  line-height: 0;
  font-size: 0; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .rack-mod-cards {
      display: block;
      width: 100%;
      height: auto; } }
  @media (min-width: 600px) {
    .rack-mod-cards {
      grid-template-columns: repeat(4, 1fr); } }
  .rack-mod-cards--big {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-end; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .rack-mod-cards--big {
        display: block;
        width: 100%;
        height: auto; } }
    @media (min-width: 600px) {
      .rack-mod-cards--big {
        grid-template-columns: repeat(3, 1fr); } }
  .rack-mod-cards--homepage {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    justify-content: flex-end; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .rack-mod-cards--homepage {
        display: block;
        width: 100%;
        height: auto; } }
    @media (min-width: 600px) {
      .rack-mod-cards--homepage {
        grid-template-columns: repeat(4, 1fr); } }
  .rack-mod-cards__column {
    display: flex;
    flex-direction: column;
    padding: 0;
    list-style: none;
    z-index: 2; }
  .rack-mod-cards__row {
    display: flex;
    flex-direction: row;
    padding: 0;
    list-style: none;
    justify-content: space-between; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .rack-mod-cards__row {
        padding: 1rem; } }
  .rack-mod-cards__card {
    position: relative;
    height: 21rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    padding: 1rem 1rem 0;
    border-radius: 0.2rem;
    overflow: hidden;
    transform: scale(1); }
    .rack-mod-cards__card::after {
      content: "";
      height: 40%;
      width: 100%;
      position: absolute;
      left: 0;
      bottom: 0;
      z-index: -1;
      background: -webkit-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: -o-linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      background: linear-gradient(rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.5) 100%);
      opacity: 1;
      z-index: 1; }
    .rack-mod-cards__cardLink {
      display: block;
      width: 100%;
      text-decoration: none;
      z-index: 10;
      font-size: 1.6rem;
      color: #FFF;
      font-family: "Montserrat", sans-serif;
      line-height: 130%;
      text-shadow: 0px 0 20px black; }
      .rack-mod-cards__cardLink:before {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    .rack-mod-cards__cardLink {
      margin-bottom: 1rem; }
    .rack-mod-cards__card:last-of-type {
      margin-bottom: 0; }
    .rack-mod-cards__cardMeta {
      color: #FFF;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      text-shadow: 0px 0 20px black; }
    .rack-mod-cards__cardIcon svg {
      width: 1.5rem;
      height: 1.5rem; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .rack-mod-cards__card div {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
    .rack-mod-cards__cardImage {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: -1; }

@media (min-width: 1000px) {
  .rack-mod-cards__card {
    transition: all 200ms ease;
    cursor: pointer; }
    .rack-mod-cards__card:hover {
      transform: scale(1.03);
      box-shadow: 0 10px 80px #00000061; } }
  @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
    .rack-mod-cards__card {
      display: inline-block;
      width: 24%;
      height: 200px !important;
      margin: 0.5%; } }

@media (min-width: 1000px) {
    .rack-mod-cards__cardLink {
      font-size: 1.92rem;
      padding: 0; } }
    @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
      .rack-mod-cards__cardLink {
        padding: 0.2rem;
        position: absolute;
        bottom: 0;
        z-index: 10; } }

@media (min-width: 1000px) {
      .rack-mod-cards__cardLink:before {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }
      @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
        .rack-mod-cards__cardLink:before {
          transform: scale(100); } }
  @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
    .rack-mod-cards__cardLink {
      padding: 0 10px; } }

@media (min-width: 1000px) {
    .rack-mod-cards__card:hover .rack-mod-cards__cardImage {
      transform: scale(1.01); }
  .rack-mod-cards--homepage .rack-mod-cards__card:first-of-type {
    grid-row-start: 1;
    grid-column-start: 1;
    grid-row-end: 3;
    grid-column-end: 3;
    height: 100%; } }

/**
* This will be added to all classes,
* to rule out all third party css code collision.
**/
.rack-lay-grid__container {
  max-width: 120rem;
  width: 100%;
  margin: 0 auto; }

.rack-lay-grid__expended {
  max-width: 100rem;
  width: 100%;
  margin: 0 auto; }

.rack-lay-grid__left {
  max-width: 80rem;
  width: 100%;
  margin: 0; }

.rack-lay-grid__flex {
  display: flex;
  flex-wrap: wrap; }

.rack-lay-grid__box {
  flex: 1 1 0;
  padding: 0 1rem;
  min-width: 100%; }

.rack-lay-grid__content {
  max-width: 80rem;
  width: 100%;
  margin: 0 auto; }

.rack-lay-grid__hide {
  display: none !important; }

.rack-lay-grid--margin {
  margin-bottom: 6rem; }

@media (min-width: 900px) {
  __box {
    min-width: 100%; } }

@media (min-width: 1000px) {
  .rack-lay-grid__box {
    min-width: auto; }
  .rack-lay-grid__hide {
    display: block !important; }
  .rack-lay-grid--margin {
    margin-bottom: 6rem; } }

.rack-mod-heading--center {
  text-align: center; }

.rack-mod-heading--right {
  text-align: right; }

.rack-mod-heading__h1 {
  font-size: 2.5rem;
  display: block;
  padding: 0 1rem 1rem 1rem;
  color: #00004d;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 130%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.rack-mod-heading__h2 {
  font-size: 1.8rem;
  display: block;
  padding: 0 1rem 1rem 1rem;
  color: #00004d;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 130%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

.rack-mod-heading__h3 {
  font-size: 1.6rem;
  display: block;
  padding: 0 1rem 1rem 1rem;
  color: #00004d;
  font-weight: 700;
  font-family: "Montserrat", sans-serif;
  line-height: 130%;
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%; }

@media (min-width: 1000px) {
  .rack-mod-heading__h1 {
    font-size: 5rem;
    display: block;
    padding: 0 1rem 1rem 1rem;
    color: #00004d;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    line-height: 130%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1rem 2rem 1rem; }
  .rack-mod-heading__h2 {
    font-size: 3.6rem;
    display: block;
    padding: 0 1rem 1rem 1rem;
    color: #00004d;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    line-height: 130%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1rem 1.5rem 1rem; }
  .rack-mod-heading__h3 {
    font-size: 3.2rem;
    display: block;
    padding: 0 1rem 1rem 1rem;
    color: #00004d;
    font-weight: 700;
    font-family: "Montserrat", sans-serif;
    line-height: 130%;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1rem 1.5rem 1rem; } }

.rack-mod-label {
  background-color: #12124e;
  border-radius: 0.2rem;
  padding: 0 0.66667rem;
  margin: 0.66667rem;
  font-family: "Montserrat", sans-serif;
  font-weight: normal;
  font-size: 1.2rem;
  color: #FFF; }

.rack-com-menu {
  position: fixed;
  top: 0;
  height: 6.5rem;
  width: 100%;
  padding: 0 1.5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100; }
  .rack-com-menu__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: -100vw;
    opacity: 0;
    background: #FFF;
    height: calc(100vh - 6.5rem);
    width: 100%;
    z-index: 8;
    margin-top: 6.5rem;
    transition: all 200ms ease;
    overflow: auto; }
  .is-open .rack-com-menu__container {
    left: 0;
    opacity: 1; }
  .is-open .rack-com-menu__button--menu {
    margin-left: 2rem;
    -webkit-appearance: none; }
  .rack-com-menu.is-open-search .rack-com-menu__logo {
    opacity: 0; }
  .rack-com-menu__item {
    display: flex;
    position: relative;
    flex-direction: row;
    flex: 1;
    font-size: 1.4rem;
    max-height: 8rem;
    min-height: 4rem;
    transition: all 200ms ease; }
    .rack-com-menu__item svg {
      width: 1.2rem;
      height: 1.2rem;
      margin: auto 0.66667rem auto auto; }
    .rack-com-menu__itemLink {
      text-decoration: none;
      white-space: nowrap;
      font-weight: 700;
      padding: 0 1.5rem;
      color: #000;
      margin: auto auto auto 1rem;
      width: 100%; }
      .rack-com-menu__itemLink::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%; }
    .rack-com-menu__item::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: .1rem;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.25); }
  .rack-com-menu__logo {
    width: 17.5rem;
    margin-top: 1rem; }
  .rack-com-menu__icon svg {
    height: 100%;
    width: 100%;
    padding: 0.66667rem; }
  .rack-com-menu__icon--menu svg {
    position: absolute;
    top: 0;
    width: 3rem;
    height: 100%;
    padding: 0; }
  .rack-com-menu__button {
    transition: all 200ms ease;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 4rem;
    background-color: #0e0e3a;
    border: 0;
    border-radius: 0.2rem;
    -webkit-appearance: none; }
    .rack-com-menu__button--menu {
      width: 4rem;
      margin-left: 0;
      background-color: transparent;
      color: #FFF;
      text-transform: uppercase;
      font-family: "Montserrat", sans-serif;
      font-size: 1.4rem;
      font-weight: 700;
      margin-left: auto; }

@media screen and (min-width: 1000px) {
  .rack-com-menu {
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 4rem;
    left: 0;
    right: 0;
    max-width: 120rem;
    margin: auto;
    height: 8.5rem; }
    .rack-com-menu__container {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 4rem;
      left: 0;
      opacity: 1;
      margin-top: 0;
      overflow: inherit;
      height: 100%;
      background-color: transparent; }
    .rack-com-menu__item {
      list-style: none;
      padding: 0;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      position: relative;
      display: flex;
      flex: none;
      top: 0;
      width: auto;
      position: relative;
      height: 100%;
      margin-top: 3px; }
      .rack-com-menu__item a {
        padding: 0; }
      .rack-com-menu__item.current-menu-item {
        border-top: 3px solid deepskyblue;
        margin-top: 0; }
      .rack-com-menu__item.current-menu-item a {
        color: deepskyblue; }
      .rack-com-menu__item:hover {
        opacity: 0.8; }
      .rack-com-menu__item svg {
        display: none; }
      .rack-com-menu__item::after {
        display: none; }
      .rack-com-menu__itemLink {
        font-size: 1.6rem;
        color: #FFF;
        margin: auto; }
    .rack-com-menu__button--menu {
      display: none; }
    .rack-com-menu__button--right {
      margin-left: auto; }
    .rack-com-menu__logo {
      opacity: 1 !important;
      width: 23rem;
      margin: 0 1.5rem 0 0; } }

@media print and (color) {
  .rack-com-menu {
    position: relative;
    justify-content: center;
    background-color: #12124e;
    height: auto; }
    .rack-com-menu__container {
      display: none; }
    .rack-com-menu__item {
      list-style: none;
      padding: 0 1rem;
      position: relative;
      display: flex;
      top: 0;
      width: auto;
      position: relative;
      height: 100%; }
      .rack-com-menu__item svg {
        display: none; }
      .rack-com-menu__item:hover {
        top: -.5rem; }
      .rack-com-menu__item::after {
        display: none; }
      .rack-com-menu__itemLink {
        font-size: 1.6rem;
        color: #FFF;
        margin: auto; }
    .rack-com-menu__button--menu {
      display: none; }
    .rack-com-menu__button--right {
      margin-left: auto; }
    .rack-com-menu__logo {
      opacity: 1 !important;
      width: 21.5rem;
      margin: 1.5rem 1.5rem 0 0; } }

.rack-com-pagination {
  display: flex;
  padding: 0 1rem;
  margin-bottom: 2rem;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap; }
  .rack-com-pagination__container {
    display: flex;
    flex-direction: row;
    list-style: none;
    width: 100%;
    justify-content: center; }
  .rack-com-pagination__item {
    padding: 1rem; }
    .rack-com-pagination__item--active .rack-com-pagination__itemLink {
      color: #12124e; }
    .rack-com-pagination__itemLink {
      font-size: 1.4rem;
      font-family: "Montserrat", sans-serif;
      text-decoration: none;
      font-weight: 700;
      color: #00004d; }
  .rack-com-pagination__button {
    display: flex;
    align-items: center;
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto; }
    .rack-com-pagination__button--prev {
      margin-right: auto;
      padding-left: 0; }
      .rack-com-pagination__button--prev svg {
        margin: 0 1rem; }
    .rack-com-pagination__button--next {
      margin-left: auto;
      padding-right: 0; }
      .rack-com-pagination__button--next svg {
        margin: 0 1rem; }
    .rack-com-pagination__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .rack-com-pagination__button {
        font-size: 1.4rem;
        font-weight: 700; } }
    .rack-com-pagination__button--disabled {
      background-color: #c2c2c2;
      padding: 1rem 3.5rem;
      border-radius: 3.5rem;
      text-decoration: none;
      font-family: "Montserrat", sans-serif;
      font-size: 1.2rem;
      text-align: center;
      text-transform: uppercase;
      border: 2px solid #c2c2c2;
      -webkit-appearance: none;
      color: #8f8f8f;
      width: auto; }
      .rack-com-pagination__button--disabled:hover {
        opacity: 0.9; }
      @media screen and (min-width: 1000px) {
        .rack-com-pagination__button--disabled {
          font-size: 1.4rem;
          font-weight: 700; } }
    .rack-com-pagination__button svg {
      max-width: 1.2rem;
      max-height: 1.2rem; }

@media (min-width: 1000px) {
  .rack-com-pagination {
    padding: 1.5rem; } }

.rack-mod-rating {
  position: relative;
  z-index: 2;
  margin-bottom: auto;
  user-select: none; }
  .rack-mod-rating--center {
    text-align: center; }
    .rack-mod-rating--center .rack-mod-rating__container {
      text-align: center; }
  .rack-mod-rating--single {
    margin-bottom: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%; }
    .rack-mod-rating--single .rack-mod-rating__item {
      font-size: 2rem; }
  .rack-mod-rating__container {
    position: relative;
    padding: 0;
    z-index: 2; }
  .rack-mod-rating__item {
    padding: 0;
    display: inline-block;
    transition: color 0.5s ease;
    color: rgba(0, 0, 0, 0.4);
    text-shadow: 0px 0px 10px rgba(0, 0, 0, 0.8); }
    .rack-mod-rating__item--filled {
      color: #ebb400; }
  .rack-mod-rating__link {
    color: #12124e;
    text-decoration: none; }
  .rack-mod-rating__number {
    display: none; }
  .rack-mod-rating__form .rack-mod-rating__item {
    font-size: 4rem;
    text-shadow: unset;
    cursor: pointer; }
  #rating_1:checked ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(1) {
    color: #ebb400; }
  #rating_2:checked ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+2) {
    color: #ebb400; }
  #rating_3:checked ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+3) {
    color: #ebb400; }
  #rating_4:checked ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+4) {
    color: #ebb400; }
  #rating_5:checked ~ .rack-mod-rating__container .rack-mod-rating__item {
    color: #ebb400; }
  #rating_1:hover ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(1) {
    color: #ebb400; }
  #rating_2:hover ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+2) {
    color: #ebb400; }
  #rating_3:hover ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+3) {
    color: #ebb400; }
  #rating_4:hover ~ .rack-mod-rating__container .rack-mod-rating__item:nth-child(-n+4) {
    color: #ebb400; }
  #rating_5:hover ~ .rack-mod-rating__container .rack-mod-rating__item {
    color: #ebb400; }

@media (min-width: 1000px) {
  .rack-mod-rating__number {
    display: inline-block;
    margin: 0.5rem 1rem 0;
    font-weight: bold;
    color: #000; }
  .rack-mod-rating--single {
    justify-content: left;
    margin-bottom: 1.5rem; }
  .rack-mod-rating--single .rack-mod-rating__item {
    font-size: 4rem;
    text-shadow: unset; } }

.cd-com-blog {
  display: flex;
  flex-direction: column; }
  .cd-com-blog__title {
    flex: 1 1 0;
    flex-basis: 100%;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-align: center;
    color: #12124e; }
  .cd-com-blog__container {
    display: flex;
    flex-direction: column;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .cd-com-blog__button {
    margin: 2rem 0;
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    margin: 1rem auto; }
    .cd-com-blog__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-com-blog__button {
        font-size: 1.4rem;
        font-weight: 700; } }

@media (min-width: 767px) {
  .cd-com-blog__title {
    font-size: 3rem; }
  .cd-com-blog__subtitle {
    color: #12124e;
    font-size: 1.4rem;
    display: block;
    line-height: 1rem; }
  .cd-com-blog__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap; } }

.cd-com-hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding-top: 8.5rem;
  margin-bottom: 1rem;
  min-height: 70vh;
  flex: 1; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .cd-com-hero {
      max-height: 450px; } }
  .cd-com-hero ::first-letter {
    text-transform: capitalize; }
  .cd-com-hero--big {
    height: 100vw; }
  .cd-com-hero--single {
    height: 90vh; }
  .cd-com-hero--homepage::after {
    position: relative;
    margin: 1rem; }
  .cd-com-hero--homepage::before {
    content: '';
    width: 100%;
    height: 100%;
    z-index: 91;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #00004d;
    opacity: 0.8; }
  .cd-com-hero--homepage .cd-com-hero__title {
    margin: 1rem 0;
    text-align: center;
    color: #FFF;
    font-weight: 700;
    z-index: 95;
    position: relative;
    font-family: "Montserrat", sans-serif;
    line-height: 110%;
    margin-bottom: 1rem; }
  .cd-com-hero--homepage .cd-com-hero__subtitle {
    position: relative;
    z-index: 91;
    color: deepskyblue;
    font-size: 1.3rem;
    display: block;
    text-align: center; }
  .cd-com-hero--homepage .cd-com-hero__image {
    z-index: 90; }
  .cd-com-hero__container {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    padding: 0 1rem 2rem; }
    .cd-com-hero__container--center {
      text-align: center;
      width: 100%;
      padding-right: 1rem; }
  .cd-com-hero__image {
    object-fit: cover;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1; }
  .cd-com-hero__text {
    color: #FFF;
    font-size: 1.4rem;
    margin-bottom: 1rem;
    line-height: 180%;
    z-index: 2;
    text-shadow: 0px 0 20px black; }
  .cd-com-hero__button {
    display: inline-flex;
    text-decoration: none;
    text-align: center;
    border: 1px solid #FFF;
    color: #FFF;
    font-size: 1.4rem;
    border-radius: 0.2rem;
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    padding: 0.66667rem 1rem;
    align-self: baseline;
    align-items: center;
    min-height: 3.2rem; }
    .cd-com-hero__button svg {
      height: 1.3rem;
      width: 1.3rem;
      margin-right: 1rem; }

@media screen and (min-width: 767px) {
  .cd-com-hero {
    position: relative;
    margin-bottom: 2rem;
    height: 50vw;
    overflow: hidden;
    box-sizing: content-box;
    padding: 10rem 0;
    max-width: 120rem;
    margin: auto;
    max-height: 80vh;
    min-height: 50vh; }
    .cd-com-hero--homepage {
      display: flex; }
    .cd-com-hero--homepage .cd-com-hero__title {
      text-align: left;
      font-size: 4rem;
      font-weight: 700;
      padding-right: 1.5rem; }
    .cd-com-hero--homepage .cd-com-hero__subtitle {
      text-align: left;
      font-size: 1.8rem;
      margin-bottom: 0; }
    .cd-com-hero__navbar {
      display: none; }
    .cd-com-hero__image {
      object-fit: cover;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: -1; }
    .cd-com-hero__heading {
      font-size: 3.6rem; }
    .cd-com-hero__text {
      color: #FFF;
      font-size: 1.68rem;
      margin-bottom: 1.2rem;
      line-height: 216%;
      z-index: 2; }
    .cd-com-hero__container {
      flex: 1 0 0;
      align-items: center;
      margin: 12rem 0; }
      .cd-com-hero__container--center {
        padding-right: 1rem;
        padding-bottom: 1.5rem; }
    .cd-com-hero__button {
      display: inline-flex; } }

@media screen and (min-width: 1000px) {
  .cd-com-hero {
    position: static; }
    .cd-com-hero--homepage .cd-com-hero__title {
      font-size: 7rem; } }

.cd-com-overview {
  display: flex;
  flex-direction: column;
  padding: 0 1rem; }
  .cd-com-overview__subtitle {
    display: none; }
  .cd-com-overview--company .cd-mod-box-general__container {
    justify-content: center; }
  .cd-com-overview__title {
    flex: 1 1 0;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1.5rem;
    margin-top: 0;
    text-align: center;
    width: 100%;
    color: #12124e; }
    .cd-com-overview__title--left {
      text-align: left; }
    .cd-com-overview__title--right {
      text-align: right; }
  .cd-com-overview__button {
    margin: 2rem 0;
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    flex-basis: 100%;
    margin: 1rem; }
    .cd-com-overview__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-com-overview__button {
        font-size: 1.4rem;
        font-weight: 700; } }

@media (min-width: 1000px) {
  .cd-com-overview__title {
    font-size: 3rem;
    margin-bottom: 4.5rem; }
  .cd-com-overview__subtitle {
    color: #12124e;
    font-size: 2.1rem;
    text-align: center;
    display: block;
    line-height: 2.8rem; }
    .cd-com-overview__subtitle--right {
      text-align: right; }
    .cd-com-overview__subtitle--left {
      text-align: left; }
  .cd-com-overview__button {
    width: auto;
    flex-basis: auto;
    margin: 1.5rem auto 0; } }

.wp-block-column p {
  margin-bottom: 1.5rem; }

.wpcf7 {
  padding: 0 1rem !important; }
  .wpcf7 p {
    padding: 0; }

.wpcf7 .g-recaptcha {
  margin-bottom: 1rem; }

.wpcf7 label {
  display: block; }

.wpcf7-text {
  height: 50px;
  border: 0;
  font-size: 1.5rem;
  padding: 1rem 0;
  border-bottom: 2px solid #CCC;
  margin-bottom: 1.5rem;
  width: 100%; }

.wpcf7-textarea {
  border: 0;
  width: 100%;
  display: block;
  font-size: 1.5rem;
  padding: 1rem 0;
  border-bottom: 2px solid #CCC;
  margin-bottom: 1.5rem;
  font-family: "Montserrat", sans-serif; }

.wpcf7-submit {
  background-color: #12124e;
  padding: 1rem 3.5rem;
  border-radius: 3.5rem;
  text-decoration: none;
  font-family: "Montserrat", sans-serif;
  font-size: 1.2rem;
  text-align: center;
  text-transform: uppercase;
  border: 2px solid #12124e;
  -webkit-appearance: none;
  color: #FFF;
  width: 100%;
  max-width: 50%;
  float: right; }
  .wpcf7-submit:hover {
    opacity: 0.9; }
  @media screen and (min-width: 1000px) {
    .wpcf7-submit {
      font-size: 1.4rem;
      font-weight: 700; } }

@media (min-width: 1000px) {
  .wpcf7-field-50, .wpcf7-field-100 {
    width: 50%;
    display: inline-block;
    float: left;
    padding: 1rem;
    margin-left: -1rem; }
  .wpcf7-field-100 {
    width: 100%; } }

* {
  box-sizing: border-box; }

html, body {
  font-size: 62.5%;
  margin: 0; }

body {
  padding-top: 6.5rem;
  font-family: "Montserrat", sans-serif;
  line-height: 180%;
  font-size: 1.4rem; }
  body.is-open {
    height: 0;
    overflow: hidden; }

p, ul, figure {
  margin: 0;
  padding: 0; }

h1 {
  font-size: 2.5rem; }

h2 {
  font-size: 1.8rem; }

h3 {
  font-size: 1.6rem; }

ul li {
  list-style: none; }

p {
  line-height: 180%; }

textarea {
  max-width: 100%; }

[data-print="only"] {
  display: none !important; }

@media screen and (min-width: 1000px) {
  body {
    padding-top: 0;
    font-size: 1.6rem; } }

@media print {
  body {
    padding-top: 0;
    font-size: 1.6rem; } }

@supports (-ms-ime-align: auto) {
  .selector {
    property: value; } }

.cd-lay-breadcrumbs {
  max-width: 120rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  padding: 0 1rem;
  margin-bottom: 2rem; }
  .cd-lay-breadcrumbs a {
    color: deepskyblue;
    text-decoration: none;
    font-weight: 700; }
  .cd-lay-breadcrumbs span {
    font-weight: 700; }
  .cd-lay-breadcrumbs a::after {
    content: '\279C';
    color: rgba(0, 0, 0, 0.25);
    padding: 1rem;
    text-decoration: none; }

.cd-lay-content {
  max-width: 80rem;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  margin-bottom: 2rem; }
  .cd-lay-content--wide {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .cd-lay-content a {
    color: #12124e;
    text-decoration: none; }
  .cd-lay-content p, .cd-lay-content__paragraph {
    line-height: 180%;
    margin-bottom: 2rem;
    padding: 0 1rem;
    font-size: 1.4rem; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .cd-lay-content p, .cd-lay-content__paragraph {
        display: block; } }
  .cd-lay-content .mobile-down .wp-block-column:first-of-type {
    order: 2; }
  .cd-lay-content .sharedaddy li::before {
    display: none; }
  .cd-lay-content ul, .cd-lay-content ol {
    position: relative;
    display: block;
    padding: 0 1rem 1.5rem;
    margin: 0; }
  .cd-lay-content ol {
    padding: 0 3rem; }
  .cd-lay-content ul li {
    padding-left: 2rem;
    position: relative;
    line-height: 140%;
    font-size: 1.4rem; }
    .cd-lay-content ul li::before {
      content: '';
      position: absolute;
      display: block;
      height: .6rem;
      width: .6rem;
      background-color: #12124e;
      border-radius: 50%;
      left: 0;
      top: 8px; }
  .cd-lay-content img {
    max-width: 100%;
    height: auto; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .cd-lay-content img {
        width: auto;
        display: inline-block;
        height: 500px;
        line-height: 0;
        font-size: 0; } }
  .cd-lay-content h1, .cd-lay-content h2, .cd-lay-content h3, .cd-lay-content h4, .cd-lay-content h5, .cd-lay-content h6 {
    font-family: "Montserrat", sans-serif;
    font-weight: 900;
    padding: 0 1rem 0;
    color: #12124e;
    margin-top: 0;
    margin-bottom: 1rem; }
  .cd-lay-content h2 {
    font-size: 1.8rem; }

@media screen and (min-width: 767px) {
  .cd-lay-content .mobile-down .wp-block-column:first-of-type {
    order: 0; } }

@media screen and (min-width: 1000px) {
  .cd-lay-content {
    display: flex;
    flex-direction: column; }
    .cd-lay-content h2 {
      font-size: 3rem;
      margin-bottom: 1.5rem; }
    .cd-lay-content p {
      font-size: 1.68rem;
      line-height: 216%; }
    .cd-lay-content ul li {
      font-size: 1.68rem; } }

@media print {
  .cd-lay-content {
    display: flex;
    flex-direction: column; }
    .cd-lay-content p {
      font-size: 1.68rem;
      line-height: 216%; }
    .cd-lay-content img {
      display: block;
      max-width: 50%;
      margin-left: auto;
      margin-right: auto; }
    .cd-lay-content ul li {
      font-size: 1.68rem; } }

.cd-lay-footer {
  display: flex;
  flex-direction: column;
  background-color: #12124e;
  padding-top: 2rem;
  color: #FFF;
  font-size: 1.4rem; }
  .cd-lay-footer__container {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
    .cd-lay-footer__container--nomargin {
      margin-bottom: 0; }
  .cd-lay-footer__logo {
    max-width: 19rem;
    margin: 1.5rem auto; }
    .cd-lay-footer__logo svg {
      max-width: 19rem;
      height: auto;
      width: 100%; }
      .cd-lay-footer__logo svg path {
        fill: #FFF !important; }
  .cd-lay-footer__sub {
    display: block;
    width: 100%;
    position: relative;
    color: #362726;
    padding: 1.5rem 0.66667rem;
    text-align: center;
    z-index: 10; }
    .cd-lay-footer__sub a {
      display: inline-block;
      color: #12124e;
      text-decoration: none;
      padding: 0 0.66667rem !important; }
      .cd-lay-footer__sub a:after {
        content: '\021E2';
        color: #362726;
        padding-left: 0.66667rem;
        margin-right: 0.66667rem; }
  .cd-lay-footer__column {
    padding: 0 1rem;
    margin-bottom: 1.5rem; }
    .cd-lay-footer__column p {
      line-height: 180%; }
    .cd-lay-footer__column a {
      color: deepskyblue;
      text-decoration: none; }
    .cd-lay-footer__columnTitle {
      display: flex;
      color: #FFF;
      font-family: "Montserrat", sans-serif;
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 1.5rem; }
    .cd-lay-footer__column li::after {
      content: '';
      display: block;
      width: calc(100% - 1.5rem);
      height: 1px;
      margin: auto;
      background-color: #00004d;
      opacity: 0.1; }
    .cd-lay-footer__column li::after {
      width: 100%; }
    .cd-lay-footer__column li a {
      position: relative;
      display: block;
      width: 100%;
      padding: 0;
      font-size: 1.4rem;
      color: deepskyblue; }
  .cd-lay-footer__block::before {
    content: '';
    display: block;
    width: calc(100% - 1.5rem);
    height: 1px;
    margin: auto;
    background-color: white;
    opacity: 0.1; }
  .cd-lay-footer__title {
    width: 100%;
    display: block;
    position: relative;
    padding: 1.5rem 1rem;
    font-size: 1.4rem;
    font-weight: 700;
    color: #FFF;
    text-decoration: none;
    padding-right: 4rem; }
    .cd-lay-footer__title::before {
      content: '';
      background-image: url("../images/icons/chevron-left.svg");
      background-repeat: no-repeat;
      background-position: center center;
      width: 3rem;
      height: 3rem;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      transform: rotate(90deg); }
  .cd-lay-footer__logo svg {
    margin: auto 0; }
  .cd-lay-footer__copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1.2rem;
    margin: 1.5rem 0; }

@media (min-width: 767px) {
  .cd-lay-footer {
    padding-top: 6rem; }
    .cd-lay-footer__copyright {
      font-size: 1.6rem;
      padding: 0 1rem; }
      .cd-lay-footer__copyrightText {
        order: 0;
        margin-top: 0; }
      .cd-lay-footer__copyrightArtist {
        margin-left: auto; }
      .cd-lay-footer__copyright svg {
        margin: 0 0 0 1rem; }
    .cd-lay-footer__logo {
      margin-left: 0;
      padding: 0 1rem; }
    .cd-lay-footer__container {
      flex-direction: row;
      justify-content: space-between; }
    .cd-lay-footer__column {
      max-width: 20%;
      flex: 1; }
    .cd-lay-footer__print {
      width: 100%;
      max-width: 80%;
      margin: auto; }
      .cd-lay-footer__print img {
        max-width: 100%; } }

@media print {
  .cd-lay-footer {
    display: flex; }
    .cd-lay-footer__print {
      width: 100%;
      max-width: 80%;
      margin: auto; }
      .cd-lay-footer__print img {
        max-width: 100%;
        height: 200px; } }

.cd-lay-header {
  margin-bottom: 2rem; }
  .cd-lay-header::before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    display: block;
    width: 100%;
    height: 6.5rem;
    background-color: #12124e;
    z-index: 80; }
  .cd-lay-header--homepage {
    margin-top: -6.5rem;
    margin-bottom: 4rem; }

@media screen and (min-width: 1000px) {
  .cd-lay-header {
    position: relative;
    padding-top: 8.5rem; }
    .cd-lay-header::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: 8.5rem;
      background-color: #12124e;
      z-index: -1; }
    .cd-lay-header__decoration {
      display: block;
      position: absolute;
      right: 0;
      bottom: -49px;
      z-index: -1; }
    .cd-lay-header--homepage {
      padding: 0;
      margin-top: 0; }
      .cd-lay-header--homepage::before {
        height: 90%; }
    .cd-lay-header::after {
      display: none; }
    .cd-lay-header__container {
      display: flex; } }

@media print {
  .cd-lay-header {
    padding-top: 0; }
    .cd-lay-header::before {
      display: none; }
    .cd-lay-header__decoration {
      display: none; } }

.cd-lay-sidebar {
  padding: 0 1rem;
  width: 100%;
  margin-bottom: 2rem; }
  .cd-lay-sidebar__contact {
    padding: 1.5rem;
    background-color: #eff2f5; }
  .cd-lay-sidebar__image {
    max-width: auto;
    width: 100%;
    height: auto;
    margin-bottom: 2rem; }
  .cd-lay-sidebar__title {
    font-size: 2rem;
    margin-top: 0;
    color: #12124e; }

@media (min-width: 767px) {
  .cd-lay-sidebar {
    flex: 0.5;
    max-width: 32rem; } }

.cd-lay-space {
  height: 1rem; }
  .cd-lay-space--small {
    height: 0.66667rem; }
  .cd-lay-space--big {
    height: 1.5rem; }
  .cd-lay-space--section {
    height: 2rem; }

.cd-lay-subfooter {
  padding: 0 1rem;
  text-align: center;
  display: flex;
  flex-direction: column; }
  .cd-lay-subfooter__title {
    color: #FFF;
    margin-bottom: 1.5rem;
    font-size: 3rem;
    line-height: 5rem; }
  .cd-lay-subfooter__description {
    color: #FFF;
    margin-bottom: 1.5rem; }
  .cd-lay-subfooter__button {
    margin: 2rem 0;
    background-color: deepskyblue;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid deepskyblue;
    -webkit-appearance: none;
    width: auto;
    color: #FFF;
    flex-basis: 100%;
    margin: 1rem auto; }
    .cd-lay-subfooter__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-lay-subfooter__button {
        font-size: 1.4rem;
        font-weight: 700; } }
  .cd-lay-subfooter__textlink {
    color: deepskyblue;
    padding: 1rem 0;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    width: 100%;
    text-align: center;
    color: #FFF; }
    .cd-lay-subfooter__textlink:hover {
      opacity: 0.9; }

@media (min-width: 1000px) {
  .cd-lay-subfooter__title {
    font-size: 5rem; } }

.cd-lay-subheader {
  background-color: #191968;
  padding: 2rem 1.5rem;
  margin-top: -2rem;
  margin-bottom: 2rem; }
  .cd-lay-subheader__title {
    margin: 0;
    font-size: 3rem;
    line-height: 4rem;
    color: #FFF;
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }

@media (min-width: 1000px) {
  .cd-lay-subheader {
    padding: 4rem 1.5rem; }
    .cd-lay-subheader__title {
      margin: 0;
      font-size: 6rem;
      line-height: 7rem;
      color: #FFF;
      max-width: 120rem;
      margin-left: auto;
      margin-right: auto;
      width: 100%; } }

@media print {
  @page {
    size: A4; }
  * {
    -webkit-print-color-adjust: exact;
    color-adjust: exact;
    print-color-adjust: exact; }
  h1, h2, h3, h4, h5 {
    page-break-after: avoid; }
  h1 + p, h2 + p, h3 + p {
    page-break-before: avoid; }
  p img {
    page-break-inside: avoid;
    /* or 'auto' */ }
  [data-print="none"] {
    display: none; }
  [data-print="only"] {
    display: inherit !important; }
  .sharedaddy, .scroll-triggered-box, #sb_instagram {
    display: none; } }

.cd-mod-blog {
  padding: 1rem 1.5rem 0;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: 1.5rem; }
  .cd-mod-blog__image {
    max-width: 100%;
    height: auto; }
  .cd-mod-blog__date {
    position: absolute;
    font-size: 1.4rem;
    font-weight: 700;
    right: 1.5rem;
    padding: 1rem 1rem 1rem 1.5rem;
    border-radius: 3.5rem 0 0 3.5rem;
    top: 3rem;
    text-transform: uppercase;
    background-color: deepskyblue;
    color: #FFF; }
  .cd-mod-blog__title {
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 1.5rem; }
  .cd-mod-blog__link {
    text-decoration: none;
    color: #12124e; }
    .cd-mod-blog__link::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      cursor: pointer; }
  .cd-mod-blog__textlink {
    color: deepskyblue;
    padding: 1rem 0;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    width: 100%;
    margin-top: auto; }
    .cd-mod-blog__textlink:hover {
      opacity: 0.9; }
  .cd-mod-blog__button {
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    margin: 1rem auto 1.5rem; }
    .cd-mod-blog__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-blog__button {
        font-size: 1.4rem;
        font-weight: 700; } }

@media (min-width: 767px) {
  .cd-mod-blog {
    max-width: 33%; } }

.cd-mod-box-general {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  background-color: #FFF;
  padding: 1rem 1rem 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.15); }
  .cd-mod-box-general__title {
    margin-top: 1rem;
    font-size: 1.8rem; }
  .cd-mod-box-general__container {
    display: flex;
    flex-wrap: wrap; }

@media (min-width: 1000px) {
  .cd-mod-box-general__title {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
    font-size: 1.8rem !important; } }

.cd-mod-box-item {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  background-color: #FFF;
  padding: 1rem 1rem 1.5rem;
  margin-bottom: 1.5rem;
  box-shadow: 0 0 17px rgba(0, 0, 0, 0.15); }
  .cd-mod-box-item__logo {
    flex: 1 1 0;
    flex-basis: 100%;
    text-align: center;
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    border-bottom: 1px solid rgba(204, 204, 204, 0.25); }
    .cd-mod-box-item__logoImage {
      max-width: 100%;
      height: auto;
      margin: auto; }
  .cd-mod-box-item__noresults {
    display: none;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    padding: 1rem;
    margin: 1.5rem; }
  .cd-mod-box-item__column {
    display: flex;
    flex-direction: column;
    flex-basis: 50%;
    color: #12124e;
    margin-bottom: 1rem; }
    .cd-mod-box-item__column--big {
      flex: 3; }
  .cd-mod-box-item__label {
    font-size: 1.3rem; }
    .cd-mod-box-item__label--bold {
      color: #12124e;
      font-weight: 700; }
  .cd-mod-box-item__value {
    font-weight: 700; }
  .cd-mod-box-item__container {
    max-width: 120rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%; }
  .cd-mod-box-item__button {
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: 100%;
    max-width: 20rem;
    margin: auto; }
    .cd-mod-box-item__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-box-item__button {
        font-size: 1.4rem;
        font-weight: 700; } }
    .cd-mod-box-item__buttonContainer {
      display: flex;
      flex-basis: 100%;
      min-width: 100%;
      flex-direction: column;
      flex: 1; }
  .cd-mod-box-item__textlink {
    color: deepskyblue;
    padding: 1rem 0;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    width: 100%;
    text-align: center; }
    .cd-mod-box-item__textlink:hover {
      opacity: 0.9; }

@media (min-width: 767px) {
  .cd-mod-box-item {
    max-width: calc(50% - (1rem * 2));
    margin: 1rem; }
    .cd-mod-box-item__container {
      display: flex; } }

@media (min-width: 1000px) {
  .cd-mod-box-item {
    align-items: center;
    padding: 1rem;
    height: 16rem;
    max-width: none; }
    .cd-mod-box-item--big {
      height: 25rem; }
    .cd-mod-box-item__container {
      display: block; }
    .cd-mod-box-item__logo {
      display: flex;
      align-items: center;
      flex: 1 0 0;
      min-width: 15rem;
      height: 100%;
      margin-bottom: 0;
      padding-bottom: 0;
      padding: 0 1.5rem 0 1rem;
      margin-right: 1.5rem;
      border-right: 1px solid rgba(204, 204, 204, 0.25);
      border-bottom: 0px solid transparent;
      max-width: 20rem; }
    .cd-mod-box-item__column {
      flex: 1;
      flex-basis: auto;
      padding: 0 0.66667rem; }
      .cd-mod-box-item__column--big {
        flex: 3; }
    .cd-mod-box-item__button {
      margin: 0 auto; }
      .cd-mod-box-item__buttonContainer {
        display: flex;
        justify-content: center;
        min-width: auto;
        flex: 1 0 0;
        height: 100%;
        display: flex;
        flex-direction: column;
        padding: 0 1rem 0 1.5rem;
        margin-left: 1.5rem;
        border-left: 1px solid rgba(204, 204, 204, 0.25); } }

.cd-mod-boxed-filter {
  border-radius: 0.2rem;
  box-shadow: 0px 0px 17px rgba(0, 0, 0, 0.15);
  background-color: #FFF;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  z-index: 95;
  position: relative;
  padding: 1.5rem 1.5rem 0;
  /*Chrome*/ }
  .cd-mod-boxed-filter__subtitle {
    color: #12124e;
    font-size: 1.4rem;
    display: block;
    line-height: 1rem; }
  .cd-mod-boxed-filter__title {
    color: #12124e;
    font-size: 1.6rem;
    font-weight: 900;
    margin-bottom: 1.5rem; }
  .cd-mod-boxed-filter__bottom {
    display: flex;
    align-items: center;
    margin-bottom: 1.5rem; }
  .cd-mod-boxed-filter__valueContainer {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem; }
  .cd-mod-boxed-filter__amount {
    flex: 1 1 0;
    color: #12124e;
    font-weight: 900;
    padding-right: 1rem;
    white-space: nowrap;
    font-size: 1.8rem; }
  .cd-mod-boxed-filter__button {
    flex: 1 1 0;
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: 100%;
    padding: 1rem 1.5rem;
    font-size: 1rem;
    white-space: nowrap; }
    .cd-mod-boxed-filter__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-boxed-filter__button {
        font-size: 1.4rem;
        font-weight: 700; } }
  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    .cd-mod-boxed-filter input[type='range'] {
      overflow: hidden;
      width: 100%;
      -webkit-appearance: none;
      background-color: #ECECEC;
      border-radius: 0.2rem;
      margin: 0;
      padding: 0; }
    .cd-mod-boxed-filter input[type='range']::-webkit-slider-runnable-track {
      height: 15px;
      margin-left: 0;
      -webkit-appearance: none;
      color: #FFF;
      margin-top: -1px; }
    .cd-mod-boxed-filter input[type='range']::-webkit-slider-thumb {
      width: 14px;
      -webkit-appearance: none;
      height: 14px;
      margin-top: 1px;
      cursor: ew-resize;
      border-radius: 50%;
      background-color: #12124e;
      box-shadow: -1007px 0 0 1000px #43e5f7; } }

/** FF*/
input[type="range"]::-moz-range-progress {
  background-color: deepskyblue;
  height: 15px; }

input[type="range"]::-moz-range-track {
  background-color: #ECECEC;
  height: 15px; }

input[type="range"]::-moz-range-thumb {
  width: 14px;
  -webkit-appearance: none;
  height: 14px;
  margin-top: 1px;
  cursor: ew-resize;
  border-radius: 50%;
  background-color: #12124e;
  box-shadow: -1007px 0 0 1000px #43e5f7; }

/* IE*/
input[type="range"]::-ms-fill-lower {
  background-color: deepskyblue;
  height: 15px; }

input[type="range"]::-ms-fill-upper {
  background-color: #ECECEC;
  height: 15px; }

input[type="range"]::-ms-thumb {
  width: 14px;
  -webkit-appearance: none;
  height: 14px;
  margin-top: 1px;
  cursor: ew-resize;
  border-radius: 50%;
  background-color: #12124e;
  box-shadow: -1007px 0 0 1000px #43e5f7; }

@media screen and (min-width: 767px) {
  .cd-mod-boxed-filter {
    height: 32rem;
    padding: 3rem 3rem 0; }
    .cd-mod-boxed-filter__subtitle {
      font-size: 2.1rem;
      line-height: 2.8rem; }
    .cd-mod-boxed-filter__title {
      font-size: 3rem; }
    .cd-mod-boxed-filter__bottom {
      margin-top: auto;
      margin-bottom: 3rem; } }

@media screen and (min-width: 1000px) {
  .cd-mod-boxed-filter {
    height: 32rem;
    padding: 3rem 3rem 0; }
    .cd-mod-boxed-filter__subtitle {
      font-size: 2.1rem;
      line-height: 2.8rem; }
    .cd-mod-boxed-filter__title {
      font-size: 3rem; }
    .cd-mod-boxed-filter__bottom {
      margin-top: auto;
      margin-bottom: 3rem; } }

.cd-mod-company {
  display: flex;
  flex-direction: column;
  flex: 0.5 0.5 0; }
  .cd-mod-company__logo {
    max-width: 100%;
    width: auto;
    margin: 1rem auto 0;
    max-height: 10rem;
    flex-basis: auto;
    flex: 1 1 0; }
  .cd-mod-company__name {
    text-align: center;
    flex-basis: 100%;
    font-weight: 700;
    margin-top: 1rem; }
  .cd-mod-company__button {
    margin: 2rem 0;
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    flex-basis: auto;
    margin: 1rem auto auto; }
    .cd-mod-company__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-company__button {
        font-size: 1.4rem;
        font-weight: 700; } }
  .cd-mod-company__textlink {
    color: deepskyblue;
    padding: 1rem 0;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;
    font-size: 90%;
    width: 100%;
    text-align: center; }
    .cd-mod-company__textlink:hover {
      opacity: 0.9; }
  .cd-mod-company__box {
    display: flex;
    min-width: 100%;
    max-width: 100%;
    flex: 1 1 0;
    flex-direction: column;
    flex-wrap: nowrap;
    background-color: #FFF;
    padding: 1rem 1rem 1.5rem;
    box-shadow: 0 0 17px rgba(0, 0, 0, 0.15);
    margin: 0 1rem 1.5rem; }

@media screen and (min-width: 767px) {
  .cd-mod-company__box {
    min-width: 20%;
    max-width: 20%; } }

.cd-mod-faq {
  padding: 1rem;
  display: flex;
  flex-direction: column; }
  .cd-mod-faq__title {
    text-align: center;
    font-size: 1.8rem;
    font-weight: 900;
    margin-bottom: 3rem;
    color: #12124e; }
  .cd-mod-faq__list {
    border-top: 1px solid #666170;
    padding-top: 1.5rem; }
  .cd-mod-faq__item {
    padding-bottom: 1.5rem;
    border-bottom: 1px solid #666170;
    margin-bottom: 1.5rem; }
  .cd-mod-faq__link {
    color: #666170;
    text-decoration: none; }
  .cd-mod-faq__button {
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    margin: 1rem auto 1.5rem; }
    .cd-mod-faq__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-faq__button {
        font-size: 1.4rem;
        font-weight: 700; } }

.cd-mod-filter {
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  margin-bottom: 1.5rem; }
  .cd-mod-filter__select {
    width: 100%;
    height: 4rem;
    margin-bottom: 0.66667rem;
    padding: 1rem; }

@media (min-width: 1000px) {
  .cd-mod-filter {
    flex-direction: row;
    align-items: center;
    justify-content: center; }
    .cd-mod-filter__select {
      height: 4rem; }
    .cd-mod-filter__container {
      margin-right: 1rem; } }

.cd-mod-form {
  margin-bottom: 2rem; }
  .cd-mod-form--marginless {
    margin: 0; }
  .cd-mod-form__single {
    width: 100%;
    border-radius: 0.2rem;
    border-radius: 0.2rem;
    font-size: 1.4rem;
    padding: 1rem;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem; }
  .cd-mod-form__textarea {
    width: 100%;
    min-height: 15rem;
    border-radius: 0.2rem;
    border-radius: 0.2rem;
    font-size: 1.4rem;
    padding: 1rem;
    background-color: #FFF;
    border: 1px solid rgba(0, 0, 0, 0.25);
    margin-bottom: 1rem; }
  .cd-mod-form__button {
    background-color: #12124e;
    padding: 1rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: 100%; }
    .cd-mod-form__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-form__button {
        font-size: 1.4rem;
        font-weight: 700; } }
  .cd-mod-form__column {
    display: grid;
    grid-gap: 1rem;
    grid-template-rows: auto;
    grid-template-columns: repeat(1, 1fr);
    margin-bottom: 1rem; }
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
      .cd-mod-form__column {
        display: block;
        width: 100%;
        height: auto; } }
    @media (min-width: 600px) {
      .cd-mod-form__column {
        grid-template-columns: repeat(3, 1fr); } }
  .cd-mod-form__column .cd-mod-form__button, .cd-mod-form__column .cd-mod-form__single {
    margin-bottom: 0; }
  .cd-mod-form__label {
    font-family: "Montserrat", sans-serif;
    font-weight: 700;
    margin-bottom: 1rem; }

.cd-mod-icons-box {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  text-align: center;
  padding: 0 1rem;
  margin-bottom: 2rem; }
  .cd-mod-icons-box__title {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    color: #12124e;
    margin: auto auto 1.5rem;
    font-weight: 900;
    font-size: 2rem; }
  .cd-mod-icons-box__paragraph {
    max-width: 80rem;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    margin-bottom: 1.5rem; }
  .cd-mod-icons-box__iconContainer {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.66667rem; }
    .cd-mod-icons-box__iconContainer--mobile {
      display: none; }
  .cd-mod-icons-box__icon svg {
    max-height: 5rem;
    margin-bottom: 0.66667rem; }
    .cd-mod-icons-box__icon svg path {
      fill: deepskyblue; }
  .cd-mod-icons-box__iconTitle {
    flex: 1 0 0;
    font-size: 1.6rem;
    color: #12124e;
    font-weight: 700;
    margin-bottom: 1.5rem; }
  .cd-mod-icons-box__button {
    background-color: #12124e;
    padding: 1rem 3.5rem;
    border-radius: 3.5rem;
    text-decoration: none;
    font-family: "Montserrat", sans-serif;
    font-size: 1.2rem;
    text-align: center;
    text-transform: uppercase;
    border: 2px solid #12124e;
    -webkit-appearance: none;
    color: #FFF;
    width: auto;
    margin: auto; }
    .cd-mod-icons-box__button:hover {
      opacity: 0.9; }
    @media screen and (min-width: 1000px) {
      .cd-mod-icons-box__button {
        font-size: 1.4rem;
        font-weight: 700; } }

@media screen and (min-width: 767px) {
  .cd-mod-icons-box__row {
    display: flex;
    flex-direction: row;
    justify-content: center; }
  .cd-mod-icons-box__iconContainer--mobile {
    display: flex; }
  .cd-mod-icons-box__paragraph {
    margin-bottom: 1.5rem; }
  .cd-mod-icons-box__iconContainer {
    padding: 0 1.5rem;
    margin-bottom: 1.5rem; } }

.cd-mod-list {
  margin-left: -2rem; }
  .cd-mod-list__title {
    color: #12124e;
    font-weight: 700;
    margin-bottom: 2.25rem; }
  .cd-mod-list__item {
    color: #12124e;
    position: relative;
    padding-left: 5rem;
    margin-bottom: 1rem; }
    .cd-mod-list__item::after {
      font-family: 'Font Awesome\ 5 Free';
      font-weight: 900;
      color: deepskyblue;
      content: '\f00c';
      position: absolute;
      display: block;
      left: 2rem;
      top: 0rem;
      font-size: 1.8rem; }

@media (min-width: 1000px) {
  .cd-mod-list__item {
    font-size: 1.8rem; }
  .cd-mod-list__title {
    font-size: 3rem; } }

.cd-mod-rating {
  display: flex;
  flex-wrap: nowrap;
  font-size: 2.5rem;
  line-height: 2.7rem; }
  .cd-mod-rating__star {
    color: #ECECEC; }
    .cd-mod-rating__star--filled {
      color: #FFDE35; }
    .cd-mod-rating__star--half {
      position: absolute;
      top: 0;
      z-index: 1;
      overflow: hidden;
      width: 50%; }
    .cd-mod-rating__starContainer {
      position: relative; }

#s::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8); }

#s:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.8); }

.cd-mod-searchform {
  display: flex;
  margin-left: auto; }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .cd-mod-searchform {
      height: 40px;
      margin-top: -10px; }
      .cd-mod-searchform svg {
        position: relative;
        height: 3rem;
        width: 3rem;
        z-index: 10;
        left: -3px;
        top: 3px; } }
  .cd-mod-searchform__field {
    width: 0;
    padding-left: 0;
    border: 0 solid transparent;
    background-color: #0e0e3a;
    border-radius: 0.2rem 0 0 0.2rem;
    color: #FFF;
    transition: width 250ms ease;
    -webkit-appearance: none; }
    .cd-mod-searchform__field:-webkit-autofill {
      -webkit-box-shadow: 0 0 0 100px #0e0e3a inset;
      -webkit-text-fill-color: #FFF; }
    .cd-mod-searchform__field::placeholder {
      color: rgba(255, 255, 255, 0.8); }
  .cd-mod-searchform__field.is-open-search {
    width: 15rem;
    padding-left: 1rem; }
    .cd-mod-searchform__field.is-open-search + button {
      border-radius: 0 0.2rem 0.2rem 0; }

@media print and (color) {
  .cd-mod-searchform {
    display: none; } }

.cd-mod-social {
  padding: 0 1rem;
  margin-bottom: 2rem; }
  .cd-mod-social__container {
    max-width: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto; }
  .cd-mod-social__item {
    display: block;
    transition: all 200ms ease;
    border-radius: 0.2rem;
    padding: 0.66667rem; }
    .cd-mod-social__item:hover {
      transform: scale(0.95); }
    .cd-mod-social__item svg {
      width: 100%;
      height: 100%; }
    .cd-mod-social__item--facebook {
      background: #36599b; }
    .cd-mod-social__item--instagram {
      background-color: #bf3684; }
    .cd-mod-social__item--pinterest {
      background-color: #c61518; }
    .cd-mod-social__item--whatsapp {
      background-color: #4ac144; }
    .cd-mod-social__item--twitter {
      background-color: #30aadf; }
    .cd-mod-social__item--youtube {
      background-color: red; }
    .cd-mod-social__item--bloglovin {
      background-color: black; }
    .cd-mod-social__item--embed {
      background-color: black; }
  .cd-mod-social__text {
    color: #000;
    font-weight: 800;
    width: 100%;
    text-align: center;
    font-family: "Montserrat", sans-serif;
    font-size: 1.4rem;
    margin-bottom: 1rem; }
  .cd-mod-social--footer {
    display: flex;
    background-color: #00004d;
    flex-direction: row;
    padding: 1rem;
    align-items: center; }
  .cd-mod-social--footer .cd-mod-social__item {
    display: inline-block;
    margin: 0.66667rem;
    width: 3rem;
    height: 3rem; }
  .cd-mod-social--footer .cd-mod-social__text {
    color: #FFF;
    margin-right: 1rem; }

@media (min-width: 1000px) {
  .cd-mod-social__container {
    max-width: 60rem; }
  .cd-mod-social__item {
    width: 4rem;
    flex: 1;
    margin: 0 1rem; }
    .cd-mod-social__item--whatsapp {
      display: none; }
  .cd-mod-social__text {
    text-align: right;
    width: auto;
    margin-bottom: 0;
    font-size: 1.68rem; }
  .cd-mod-social--footer .cd-mod-social__container {
    flex-direction: row;
    width: 100%; }
  .cd-mod-social--footer .cd-mod-social__item {
    display: inline-block;
    max-width: 4.5rem;
    height: 4.5rem; }
    .cd-mod-social--footer .cd-mod-social__item:first-child {
      flex: 1; } }
  @media all and (min-width: 1000px) and (-ms-high-contrast: none), (min-width: 1000px) and (-ms-high-contrast: active) {
    .cd-mod-social--footer .cd-mod-social__item svg {
      position: relative;
      width: 3rem;
      height: 3rem;
      left: 3px; } }
