@media print {

  @page {
    size: A4;
  }

   * {
     -webkit-print-color-adjust: exact;
      color-adjust: exact;
      print-color-adjust: exact;
   }

   h1, h2, h3, h4, h5 {
     page-break-after: avoid;
   }

   h1+p, h2+p, h3+p {
    page-break-before: avoid;
  }

  p img {
    page-break-inside: avoid; /* or 'auto' */
  }


   [data-print="none"] {
     display: none;
   }

   [data-print="only"] {
     display: inherit !important;
   }

   // third party
   .sharedaddy, .scroll-triggered-box, #sb_instagram {
     display: none;
   }
}
