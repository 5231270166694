// module name
$name: 'sidebar';
$base: $style-prefix + $layout + $name;

.#{$base} {
  padding: 0 $spacing;
  width: 100%;
  margin-bottom: $spacing-section;

  &__contact {
    padding: $spacing-big;
    background-color: $color-background;
  }

  &__image {
    max-width: auto;
    width: 100%;
    height: auto;
    margin-bottom: $spacing-section;
  }

  &__title {
    font-size: 2rem;
    margin-top: 0;
    color: $color-brand;
  }
}

@media (min-width: $media-tablet) {
  .#{$base} {
    flex: 0.5;
    max-width: 32rem;
  }
}
