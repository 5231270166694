// module name
$name: 'boxed-filter';
$base: $style-prefix + $module + $name;

.#{$base} {
  @include box;
  background-color: #FFF;
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  z-index: 95;
  position: relative;
  padding: $spacing-big $spacing-big 0;

  &__subtitle {
    color: $color-brand;
    font-size: $font-subheading-size;
    display: block;
    line-height: 1rem;
  }

  &__title {
    color: $color-brand;
    font-size: 1.6rem;
    font-weight: $font-strong;
    margin-bottom: $spacing-big;
  }

  &__bottom {
    display: flex;
    align-items: center;
    margin-bottom: $spacing-big;
  }

  &__value {

    &Container {
      display: flex;
      justify-content: space-between;
      margin-bottom: $spacing-big;
    }

  }

  &__amount {
    flex: 1 1 0;
    color: $color-brand;
    font-weight: $font-strong;
    padding-right: $spacing;
    white-space: nowrap;
    font-size: 1.8rem;
  }

  &__button {
    flex: 1 1 0;
    @include button();
    padding: $spacing $spacing-big;
    font-size: 1rem;
    white-space: nowrap;
  }

  /*Chrome*/
  @media screen and (-webkit-min-device-pixel-ratio:0) {
    input[type='range'] {
      overflow: hidden;
      width: 100%;
      -webkit-appearance: none;
      background-color: #ECECEC;
      border-radius: $border-radius;
      margin: 0;
      padding: 0;
    }

    input[type='range']::-webkit-slider-runnable-track {
      height: 15px;
      margin-left: 0;
      -webkit-appearance: none;
      color: #FFF;
      margin-top: -1px;
    }

    input[type='range']::-webkit-slider-thumb {
      width: 14px;
      -webkit-appearance: none;
      height: 14px;
      margin-top: 1px;
      cursor: ew-resize;
      border-radius: 50%;
      background-color: $color-brand;
      box-shadow: -1007px 0 0 1000px #43e5f7;
    }

  }

}
  /** FF*/
  input[type="range"]::-moz-range-progress {
    background-color: $color-brand-lighter;
    height: 15px;
  }
  input[type="range"]::-moz-range-track {
    background-color: #ECECEC;
    height: 15px;
  }

  input[type="range"]::-moz-range-thumb {
    width: 14px;
    -webkit-appearance: none;
    height: 14px;
    margin-top: 1px;
    cursor: ew-resize;
    border-radius: 50%;
    background-color: $color-brand;
    box-shadow: -1007px 0 0 1000px #43e5f7;
  }

  /* IE*/
  input[type="range"]::-ms-fill-lower {
    background-color: $color-brand-lighter;
    height: 15px;
  }
  input[type="range"]::-ms-fill-upper {
    background-color: #ECECEC;
    height: 15px;
  }

  input[type="range"]::-ms-thumb {
    width: 14px;
    -webkit-appearance: none;
    height: 14px;
    margin-top: 1px;
    cursor: ew-resize;
    border-radius: 50%;
    background-color: $color-brand;
    box-shadow: -1007px 0 0 1000px #43e5f7;
  }


@media screen and (min-width: $media-tablet) {
  .#{$base} {
  	height: 32rem;
    padding: ($spacing-big * 2) ($spacing-big * 2) 0;

    &__subtitle {
      font-size: 2.1rem;
      line-height: 2.8rem;
    }

    &__title {
      font-size: 3rem;
    }

    &__bottom {
      margin-top: auto;
      margin-bottom: $spacing-big * 2;
    }

  }
}

@media screen and (min-width: $media-desktop) {

  .#{$base} {
  	height: 32rem;
    padding: ($spacing-big * 2) ($spacing-big * 2) 0;

    &__subtitle {
      font-size: 2.1rem;
      line-height: 2.8rem;
    }

    &__title {
      font-size: 3rem;
    }

    &__bottom {
      margin-top: auto;
      margin-bottom: $spacing-big * 2;
    }

  }

}
