// module name
$name: 'menu';
$base: $prefix + $component + $name;

// Mobile menu
.#{$base} {
  position: fixed;
  top: 0;
  height: $menu-bar-height;
  width: 100%;
  padding: 0 $spacing-big;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 100;

  &__container {
    display: flex;
    position: absolute;
    flex-direction: column;
    top: 0;
    left: -100vw;
    opacity: 0;
    background: #FFF;
    height: calc(100vh - #{$menu-bar-height});
    width: 100%;
    z-index: 8;
    margin-top: $menu-bar-height;
    transition: all 200ms ease;
    overflow: auto;
  }

  .is-open & {

    &__container {
      left: 0;
      opacity: 1;
    }

    &__button--menu {
      margin-left: 2rem;
      -webkit-appearance: none;
    }

  }

  &.is-open-search & {
    &__logo {
      opacity: 0;
    }
  }

  &__item {
    display: flex;
    position: relative;
    flex-direction: row;
    flex: 1;
    font-size: 1.4rem;
    max-height: $menu-item-max;
    min-height: $menu-item-min;
    transition: all 200ms ease;

    svg {
      width: 1.2rem;
      height: 1.2rem;
      margin: auto $spacing-small auto auto;
    }

    &Link {
      text-decoration: none;
      white-space: nowrap;
      font-weight: $font-bold;
      padding: 0 $spacing-big;
      color: #000;
      margin: auto auto auto $spacing;
      width: 100%;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
      }

    }
    &::after {
      content: '';
      position: absolute;
      left: 0;
      bottom: 0;
      height: .1rem;
      width: 100%;
      background-color: $color-gray;
    }
  }


  &__logo {
    width: 17.5rem;
    margin-top: $spacing;
  }

  &__icon {

    svg {
      height: 100%;
      width: 100%;
      padding: $spacing-small;
    }

    &--menu svg {
      position: absolute;
      top: 0;
      width: 3rem;
      height: 100%;
      padding: 0;
    }

  }

  &__button {
    transition: all 200ms ease;
    position: relative;
    display: flex;
    align-items: center;
    height: 100%;
    width: 4rem;
    background-color: $color-brand-darker;
    border: 0;
    border-radius: $border-radius;
    -webkit-appearance: none;

    &--menu {
      width: 4rem;
      margin-left: 0;
      background-color: transparent;
      color: #FFF;
      text-transform: uppercase;
      font-family: $font-special-family;
      font-size: 1.4rem;
      font-weight: $font-bold;
      margin-left: auto;
    }

    &--left {

    }

    &--right {

    }

  }

}

@media screen and (min-width: $media-desktop) {
  .#{$base} {
    position: absolute;
    justify-content: flex-end;
    margin-bottom: 4rem;
    left: 0;
    right: 0;
    max-width: $grid-container;
    margin: auto;
    height: 8.5rem;

    &__container {
      display: flex;
      position: relative;
      flex-direction: row;
      justify-content: flex-end;
      padding-right: 4rem;
      left: 0;
      opacity: 1;
      margin-top: 0;
      overflow: inherit;
      height: 100%;
      background-color: transparent;
    }

    &__item {
      list-style: none;
      padding: 0;
      margin-left: $spacing-big;
      margin-right: $spacing-big;
      position: relative;
      display: flex;
      flex: none;
      top: 0;
      width: auto;
      position: relative;
      height: 100%;
      margin-top: 3px;

      a {
        padding: 0;
      }

      &.current-menu-item {
        border-top: 3px solid $color-brand-lighter;
        margin-top: 0;
      }

      &.current-menu-item a {
        color: $color-brand-lighter;
      }

      &:hover {
        opacity: 0.8;
      }

      & svg {
        display: none;
      }

      &::after { display: none; }

      &Link {
        font-size: $header-fontsize-menu;
        color: #FFF;
        margin: auto;

      }
    }

    &__button--menu {
      display: none;
    }

    &__button {

      &--right {
        margin-left: auto;
      }

    }

    &__logo {
      opacity: 1 !important;
      width: 20rem + ($spacing-big * 2);
      margin: 0 $spacing-big 0 0;
    }

  }
}

@media print and (color) {
  .#{$base} {
    position: relative;
    justify-content: center;
    background-color: $color-brand;
    height: auto;

    &__container {
      display: none;
    }

    &__item {
      list-style: none;
      padding: 0 $spacing;
      position: relative;
      display: flex;
      top: 0;
      width: auto;
      position: relative;
      height: 100%;

      & svg {
        display: none;
      }

      &:hover {
        top: -.5rem;
      }
      &::after { display: none; }

      &Link {
        font-size: $header-fontsize-menu;
        color: #FFF;
        margin: auto;

      }
    }


    &__button--menu {
      display: none;
    }

    &__button {

      &--right {
        margin-left: auto;
      }

    }

    &__logo {
      opacity: 1 !important;
      width: 20rem + $spacing-big;
      margin: $spacing-big $spacing-big 0 0;
    }
  }
}
