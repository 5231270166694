// module name
$name: 'grid';
$base: $prefix + $layout + $name;

.#{$base} {

  &__container {
    max-width: $grid-container;
    width: 100%;
    margin: 0 auto;
  }

  &__expended {
    max-width: $grid-expended;
    width: 100%;
    margin: 0 auto;
  }

  &__left {
    max-width: $grid-content;
    width: 100%;
    margin: 0;
  }

  &__flex {
    display: flex;
    flex-wrap: wrap;
  }

  &__box {
    flex: 1 1 0;
    padding: 0 $spacing;
    min-width: 100%;
  }

  &__content {
    max-width: $grid-content;
    width: 100%;
    margin: 0 auto;
  }

  &__hide {
    display: none !important;
  }

  &--margin {
    margin-bottom: $spacing-section * 3;
  }

}

@media (min-width: 900px) {
  &__box {
    min-width: 100%;
  }
}


@media (min-width: $media-desktop) {
  .#{$base} {

    &__box {
      min-width: auto;
    }

    &__hide {
      display: block !important;
    }

    &--margin {
      margin-bottom: $spacing-section * 3;
    }
    
  }
}
