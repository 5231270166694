// module name
$name: 'social';
$base: $style-prefix + $module + $name;

.#{$base} {
  padding: 0 $spacing;
  margin-bottom: $spacing-section;

  &__container {
    max-width: 35rem;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    margin: auto;
  }

  &__item {
    display: block;
    transition: all 200ms ease;
    border-radius: $border-radius;
    padding: $spacing-small;

    &:hover {
      transform: scale(0.95);
    }

    svg {
      width: 100%;
      height: 100%;
    }

    &--facebook {
      background: hsl(219, 48%, 41%);
    }

    &--instagram {
      background-color: hsl(326, 56%, 48%);
    }

    &--pinterest {
      background-color: hsl(359, 81%, 43%);
    }

    &--whatsapp {
      background-color: hsl(117, 50%, 51%);
    }

    &--twitter {
      background-color: hsl(198, 73%, 53%);
    }

    &--youtube {
      background-color: hsl(0, 100%, 50%);
    }

    &--bloglovin {
      background-color: hsl(0, 0%, 0%); 
    }

    &--embed {
      background-color: hsl(0, 0%, 0%);
    }

  }

  &__text {
    color: #000;
    font-weight: 800;
    width: 100%;
    text-align: center;
    font-family: $font-special-family;
    font-size: $font-paragraph-size;
    margin-bottom: $spacing;
  }

  &--footer {
    display: flex;
    background-color: $color-primary;
    flex-direction: row;
    padding: $spacing;
    align-items: center;
  }

  &--footer & {

    &__item {
      display: inline-block;
      margin: $spacing-small;
      width: 3rem;
      height: 3rem;
    }

    &__text {
      color: #FFF;
      margin-right: $spacing;
    }

  }

}

@media (min-width: $media-desktop) {
  .#{$base} {

    &__container {
      max-width: 60rem;
    }

    &__item {
      width: 4rem;
      flex: 1;
      margin: 0 $spacing;

      &--whatsapp {
        display: none;
      }

    }

    &__text {
      text-align: right;
      width: auto;
      margin-bottom: 0;
      font-size: $font-paragraph-size * $desktop-factor;
    }

    &--footer & {

      &__container {
        flex-direction: row;
        width: 100%;
      }

      &__item {
        display: inline-block;
        max-width: 4.5rem;
        height: 4.5rem;

        &:first-child {
          flex: 1;
        }

        // IE 11 styling.
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          svg {
            position: relative;
            width: 3rem;
            height: 3rem;
            left: 3px;
          }
        }

      }
    }

  }
}
