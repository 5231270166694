// module name
$name: 'footer';
$base: $style-prefix + $layout + $name;

.#{$base} {
  display: flex;
  flex-direction: column;
  background-color: $color-brand;
  padding-top: $spacing-section;
  color: #FFF;
  font-size: 1.4rem;

  &__container {
    display: flex;
    flex-direction: column;
    margin-bottom: $spacing-section;
    @include grid-container;

    &--nomargin {
      margin-bottom: 0;
    }
  }

  &__logo {
    max-width: 19rem;
    margin: $spacing-big auto;

    svg {
      max-width: 19rem;
      height: auto;
      width: 100%;

      path {
        fill: #FFF !important;
      }
    }


  }

  &__sub {
    display: block;
    width: 100%;
    position: relative;
    color: $font-base-color;
    padding: $spacing-big $spacing-small;
    text-align: center;
    z-index: 10;

    // Yoast SEO breadcrumbs links
    a {
      display: inline-block;
      color: $color-brand;
      text-decoration: none;
      padding: 0 $spacing-small !important;
      &:after {
        content: '\021E2';
        color: $font-base-color;
        padding-left: $spacing-small;
        margin-right: $spacing-small;
      }
    }
  }

  &__column {
    padding: 0 $spacing;
    margin-bottom: $spacing-big;

    p {
      line-height: $font-base-lineheight;
    }

    a {
      color: $color-brand-lighter;
      text-decoration: none;
    }

    &Title {
      display: flex;
      color: #FFF;
      font-family: $font-special-family;
      font-size: 1.8rem;
      font-weight: $font-bold;
      margin-bottom: $spacing-big;
    }

    & li {
      @include seperator;
      &::after {
        width: 100%;
      }

      a {
        position: relative;
        display: block;
        width: 100%;
        padding: 0;
        font-size: 1.4rem;
        color: $color-brand-lighter;
      }
    }

  }

  &__block {
    @include seperator($color: hsl(0, 0%, 100%), $position: 'before');
  }

  &__title {
    width: 100%;
    display: block;
    position: relative;
    padding: $spacing-big $spacing;
    font-size: 1.4rem;
    font-weight: $font-bold;
    color: #FFF;
    text-decoration: none;
    @include arrow($position: collapse);
  }

  &__logo {
    svg { margin: auto 0; }
  }

  &__copyright {
    display: flex;
    flex-direction: column;
    text-align: center;
    margin-bottom: $spacing-section;
    font-size: 1.2rem;
    margin: $spacing-big 0;
  }

}

@media (min-width: $media-tablet) {
  .#{$base} {
    padding-top: $spacing-section * 3;

    &__copyright {
      font-size: 1.6rem;
      padding: 0 $spacing;

      &Text {
        order: 0;
        margin-top: 0;
      }

      &Artist {
        margin-left: auto;
      }

      svg {
        margin: 0 0 0 $spacing;
      }
    }

    &__logo {
      margin-left: 0;
      padding: 0 $spacing;
    }

    &__container {
      flex-direction: row;
      justify-content: space-between;
    }

    &__column {
      max-width: 20%;
      flex: 1;
    }

    &__print {
      width: 100%;
      max-width: 80%;
      margin: auto;

      img {
        max-width: 100%;
      }
    }

  }
}

@media print {
  .#{$base} {
    display: flex;

    &__print {
      width: 100%;
      max-width: 80%;
      margin: auto;

      img {
        max-width: 100%;
        height: 200px;
      }
    }

  }
}
