$name: 'breadcrumbs';
$base: $style-prefix + $layout + $name;

.#{$base} {
  @include grid-container;
  padding: 0 $spacing;
  margin-bottom: $spacing-section;

  a {
    color: $color-brand-lighter;
    text-decoration: none;
    font-weight: $font-bold;
  }

  span {
    font-weight: $font-bold;
  }

  a::after {
    content: '\279C';
    color: $color-gray;
    padding: $spacing;
    text-decoration: none;
  }

}
