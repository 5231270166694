// module name
$name: 'blog';
$base: $style-prefix + $module + $name;

.#{$base} {
  padding: $spacing $spacing-big 0;
  display: flex;
  position: relative;
  flex-direction: column;
  margin-bottom: $spacing-big;

  &__image {
    max-width: 100%;
    height: auto;
  }

  &__date {
    position: absolute;
    font-size: 1.4rem;
    font-weight: $font-bold;
    right: $spacing-big;
    padding: $spacing $spacing $spacing $spacing-big;
    border-radius: 3.5rem 0 0 3.5rem;
    top: $spacing-big * 2;
    text-transform: uppercase;
    background-color: $color-brand-lighter;
    color: #FFF;
  }

  &__title {
    font-size: 1.8rem;
    font-weight: $font-strong;
    margin-bottom: $spacing-big;
  }

  &__link {
    text-decoration: none;
    color: $color-brand;

    &::before {
      content: '';
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      cursor: pointer;
    }
  }

  &__textlink {
    @include textlink;
    width: 100%;
    margin-top: auto;
  }


  &__button {
    @include button($width: auto);
    margin: $spacing auto $spacing-big;
  }

}

@media (min-width: $media-tablet) {

  .#{$base} {
    max-width: 33%;
  }

}
